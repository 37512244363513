import React, { Component } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import BlogPreview from "../components/blog-preview"
import { graphql } from 'gatsby'

export class BlogIndex extends Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const post = this.props.data.allNewsApiTopHeadlines.nodes

    return (
      <div>
        <Helmet title={siteTitle} />
        <Layout>
          <div className="container">
            <div className="text-center pad-40">
              <h2 className="section-title">Noticias ...!!!</h2>
            </div>
            <ul className="article-list row">
              {post.map((node) => {
                return (
                  <li key={node.id} className="col-lg-6">
                    <BlogPreview blog={node} />
                  </li>
                )
              })}
            </ul>
          </div>
        </Layout>
      </div>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
   
        site {
          siteMetadata {
            title
            description
          }
        }

        allNewsApiTopHeadlines(filter: {urlToImage: {nin: "null"}}, limit: 25, sort: {order: DESC, fields: publishedAt}) {
          totalCount
          nodes {
            author
            description
            id
            title
            slug
            urlToImage
            publishedAt(fromNow: true)
          }
        }
       
  }
`
